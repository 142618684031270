import CryptoJS from "crypto-js";

const KEY = "RoP9q99oEkJ9OHXGxmHE5P6yKfkJsbQ1";

export function isMobile() {
    if (
        window.navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true;
    }
    return false;
}

export function encryptValue(value) {
    var keyHex = CryptoJS.enc.Utf8.parse(KEY);
    var encrypted = CryptoJS.DES.encrypt(value, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.ciphertext.toString();
}

export function decryptValue(value) {
    const ciphertext = value;
    const key = CryptoJS.enc.Utf8.parse(KEY);
    const decrypted = CryptoJS.DES.decrypt({
            ciphertext: CryptoJS.enc.Hex.parse(ciphertext),
        },
        key, {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export const TagColorList = [
    // {
    //   color: "white",
    //   background: "#fe5024",
    // },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f4f0ff",
    },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f9f9f9",
    },
    // {
    //     color: "white",
    //     background: "#f4a101",
    // },
    // {
    //     color: "white",
    //     background: "#fe5024",
    // },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f4f0ff",
    },
    {
        color: "#1A202C",
        background: "#f2fdf6",
    },
    {
        color: "#1A202C",
        background: "#f9f9f9",
    },
    // {
    //     color: "white",
    //     background: "#f4a101",
    // },
];