import Vue from "vue";
import VueRouter from "vue-router";
import { getUserId, getUserType } from "@/utils/store";

Vue.use(VueRouter);
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => err);
};

let routes;
routes = [{
        path: "/",
        redirect: "/publisher",
        meta: {
            requiresAuth: true,
            title: "Publisher",
        },
    },
    {
        path: "/publisher/landingPage",
        name: "PublisherLandingPage",
        component: () =>
            import ("../views/publisher/landingPage.vue"),
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import ("../views/publisher/login.vue"),
        meta: {
            requiresAuth: false,
            title: "Please input email",
        },
    },
    {
        path: "/login-publisher/pwd",
        name: "login-publisher-pwd",
        component: () =>
            import ("../views/publisher/InputPwd.vue"),
        meta: {
            requiresAuth: false,
            title: "Please input pincode",
        },
    },
    {
        path: "/publisher",
        name: "publisher",
        redirect: "/publisher/my-articles",
        component: () =>
            import ("../views/publishHome.vue"),
        children: [{
                path: "my-articles",
                component: () =>
                    import ("../views/publisher/article.vue"),
                meta: {
                    requiresAuth: true,
                    title: "My Articles",
                },
            },
            {
                path: "view-article",
                component: () =>
                    import ("../views/publisher/articleEdit.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Article",
                },
            },
            {
                path: "settings",
                component: () =>
                    import ("../views/publisher/settings.vue"),
                meta: {
                    requiresAuth: true,
                    title: "Settings",
                },
            },
        ],
    },
    {
        path: "/article-detail",
        component: () =>
            import ("../views/article.vue"),
        meta: {
            requiresAuth: false,
            title: "Article Detail",
        },
    },
];


const router = new VueRouter({
    linkActiveClass: "active",
    routes,
});
router.beforeEach((to, from, next) => {
    let userId = getUserId();
    let user_type = getUserType();
    if (to.meta.requiresAuth && !userId) {
        next({
            path: "/login",
        });
    } else {
        next();
    }
});
export default router;
